import request from '../../utils/request'

// 提交
export function savePosition(data = {}) {
  return request({
    url: data.id ? '/website/staff/update' : '/website/staff/save',
    method: data.id ? 'put' : 'post',
    data
  })
}
// 删除
export function handlDel(data = []) {
  return request({
    url: '/website/staff/delete',
    method: 'DELETE',
    data
  })
}
