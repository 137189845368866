<template>
  <div class="home">
    <el-dialog title="招聘记录" :visible.sync="dialogTableVisible" :close-on-click-modal="false">
      <el-table :data="gridData">
        <el-table-column property="name" label="姓名" />
        <el-table-column property="phone" label="手机号" />

        <el-table-column property="interviewTime" label="面试时间" />
        <el-table-column property="interviewType" label="面试方式" />
        <!-- <el-table-column property="status" label="状态" /> -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <a :href="scope.row.resumeUrl">
              <el-button type="text" size="small">下载简历</el-button>
            </a>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog :title="`${formType === 1 ? '编辑' : '新增'}招聘`" :visible.sync="dialogFormVisible" :before-close="handleClose" :close-on-click-modal="false">
      <el-form :model="form" size="small" style="height:350px;overflow-y:auto">
        <el-form-item label="职位名" :label-width="formLabelWidth">
          <el-input v-model="form.jobTitle" autocomplete="off" placeholder="请输入职位名" />
        </el-form-item>
        <el-form-item label="入职部门" :label-width="formLabelWidth">
          <el-input v-model="form.department" autocomplete="off" />
        </el-form-item>
        <el-form-item label="薪资" :label-width="formLabelWidth">
          <el-input v-model="form.salary" autocomplete="off" />
        </el-form-item>
        <el-form-item label="办公地点" :label-width="formLabelWidth">
          <el-input v-model="form.officeLocation" autocomplete="off" />
        </el-form-item>
        <el-form-item label="岗位职责" :label-width="formLabelWidth">
          <el-input v-model="form.responsibilities" type="textarea" autocomplete="off" :autosize="{ minRows: 10, maxRows: 10}" />
        </el-form-item>
        <el-form-item label="岗位要求" :label-width="formLabelWidth">
          <el-input v-model="form.jobRequirements" type="textarea" autocomplete="off" :autosize="{ minRows: 10, maxRows: 10}" />
        </el-form-item>
        <!-- <el-form-item label="状态" :label-width="formLabelWidth">
          <el-input v-model="form.status" autocomplete="off" />
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleClose">取 消</el-button>
        <el-button type="primary" :loading="subLoading" size="small" @click="handleSubmit()">确 定</el-button>
      </div>
    </el-dialog>
    <div class="table">
      <div class="tableinput">
        <el-input
          v-model="query.name"
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          style="width:174px"
          size="mini"
        />
        <el-button type="primary" size="mini" @click="gettableData">查询</el-button>
        <div style="float:right;">
          <el-button type="success" size="mini" @click="handleEdit()">新增</el-button>
          <el-button type="danger" size="mini" @click="BatchhandleDelet()">批量删除</el-button>
        </div>
      </div>
      <div>
        <el-table :data="tableData" stripe style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" />
          <el-table-column prop="jobTitle" label="职位名" width="180" />
          <el-table-column prop="department" label="入职部门" width="140" />
          <el-table-column prop="salary" label="薪资" width="100" />
          <el-table-column prop="officeLocation" label="办公地点" width="200" />
          <el-table-column prop="responsibilities" label="岗位职责" width="200">
            <el-tooltip slot-scope="scope" class="item" effect="dark" placement="top">
              <div slot="content" v-html="scope.row.responsibilities.replaceAll('\n', '<br/>')">}</div>
              <div v-html="scope.row.responsibilities.replaceAll('\n', '<br/>')">}</div>
            </el-tooltip>
          </el-table-column>
          <el-table-column prop="jobRequirements" label="岗位要求" width="220">
            <el-tooltip slot-scope="scope" class="item" effect="dark" placement="top">
              <div slot="content" v-html="scope.row.jobRequirements.replaceAll('\n', '<br/>')">}</div>
              <div v-html="scope.row.jobRequirements.replaceAll('\n', '<br/>')">}</div>
            </el-tooltip>
          </el-table-column>

          <!-- <el-table-column prop="status" label="状态" /> -->
          <el-table-column prop="address" label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="dialogTableVisible = true,getData(scope.row)">应聘记录</el-button>
              <el-button
                type="text"
                icon="el-icon-edit"
                size="small"
                @click="handleEdit(scope.row)"
              >编辑</el-button>
              <el-button type="text" size="small" @click="handleDelet(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <div class="block">
          <el-pagination
            :current-page="query.page"
            :page-sizes="[ 5 ,10, 20, 30, 40]"
            :page-size="query.limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            size="small"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserLog, getUsermanagement } from '../../api/admin/homePage'
import { savePosition, handlDel } from '@/api/admin/position'
export default {
  data() {
    return {
      newsLoading: false,
      gridData: [],
      tableData: [],
      idData: [
        {
          name: '邱',
          phoneNumber: '12345876534',
          resume: '',
          interviewTime: '2020-10-9',
          interviewMethod: '电话',
          state: '待处理'
        }
      ],
      dialogTableVisible: false,
      dialogFormVisible: false,
      form: {
        jobTitle: '',
        department: '',
        officeLocation: '',
        responsibilities: '',
        jobRequirements: '',
        status: '',
        salary: ''
      },
      ids: [],
      total: 0,
      formLabelWidth: '120px',
      subLoading: false,
      query: {
        page: 1,
        limit: 20,
        name: ''
      },
      recordapply: {
        page: 1,
        limit: 2,
        name: ''
      },
      formType: 0 // 0新增 1编辑
    }
  },
  created() {
    this.gettableData()
    // this.getData()
    // this.handleSubmit()
  },
  methods: {
    // 点击取消，弹窗关闭
    handleClose() {
      this.dialogFormVisible = false
      this.form = {}
    },
    // 编辑
    handleEdit(row) {
      // 0新增 1编辑
      this.formType = row ? 1 : 0
      console.log('handleEdit', row)
      if (row) {
        this.form = JSON.parse(JSON.stringify(row))
      }
      this.dialogFormVisible = true
    },
    // 提交
    handleSubmit() {
      this.subLoading = true
      savePosition(this.form).then(res => {
        this.subLoading = false
        if (this.formType === 0) {
          this.query.page = 1
        }
        this.handleClose()
        this.gettableData()
      }).catch(e => {
        this.subLoading = false
      })
    },
    // 招聘详情数据
    getData(row) {
      getUserLog({
        staffId: row.id
      })
        .then(res => {
          console.log('getUserLog', res)
          this.gridData = res.data.item
        })
        .catch(e => {
          console.error(e)
        })
    },
    // 首页招聘数据
    gettableData() {
      getUsermanagement(this.query)
        .then(res => {
          console.log('res', res)
          this.tableData = res.data.items
          this.total = res.data.total
        })
    },
    // 批量删除
    BatchhandleDelet() {
      if (this.ids.length <= 0) {
        this.$notify({
          title: '警告',
          message: '请选择要删除的行',
          duration: 1500,
          type: 'warning'
        })
        return
      }
      this.newsLoading = true
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          handlDel(this.ids)
            .then(res => {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.getData()
            })
        })
        .catch(e => {
          this.newsLoading = false
          console.error(e)
          console.log(e, '取消 删除')
        })
    },
    // 删除
    handleDelet(row) {
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          handlDel([row.id])
            .then(res => {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.getData()
            })
        })
        .catch(e => {
          this.newsLoading = false
          console.error(e)
          console.log(e, '取消 删除')
        })
    },
    // 选取某一行数据
    handleSelectionChange(val) {
      console.log('handleSelectionChange', val)
      this.ids = val.map(o => o.id)
      console.log('ids', this.ids)
    },
    // 分页
    handleSizeChange(e) {
      console.log('handleSizeChange', e)
      this.query.limit = e
      this.gettableData()
    },
    handleCurrentChange(e) {
      console.log('handleCurrentChange', e)
      this.query.page = e
      this.gettableData()
    }
  }
}
</script>

<style lang="scss" >
.table {
  border: 1px solid #ddd;
  .cell{
       width: 200px;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 这里是超出几行省略 */

  }
  .tableinput {
    border-bottom: 1px solid #ddd;
    padding: 10px;
    .el-button {
      margin-left: 10px;
    }
  }
}
/* .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
} */
.el-pagination {
  margin: 10px;
  text-align: center;
}
</style>

