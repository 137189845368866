import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.scss'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
import VueClipboard from 'vue-clipboard2'
import animated from 'animate.css'

Vue.use(animated)
Vue.use(VueClipboard)
Vue.config.productionTip = false
Vue.use(ElementUI)

new Vue({
  router,
  // store,
  render: h => h(App)
}).$mount('#app')
