import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/admin/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../layout/index.vue'),
    redirect: '/',
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/home.vue')
      },
      {
        path: '/hezhongservice',
        name: 'hezhongservice',
        component: () => import('../views/hezhongservice.vue')
      },
      {
        path: '/hezhongdynamic',
        name: 'hezhongdynamic',
        component: () => import('../views/hezhongdynamic.vue')
      },
      {
        path: '/contacts',
        name: 'contacts',
        component: () => import('../views/contacts.vue')
      },
      {
        path: '/hezhongabout',
        name: 'hezhongabout',
        component: () => import('../views/hezhongabout.vue')
      },
      {
        path: '/recruit',
        name: 'recruit',
        component: () => import('../views/recruit.vue')
      },
      {
        path: '/servicedetails',
        name: 'servicedatails',
        component: () => import('../views/servicedetails.vue')
      },
      {
        path: '/seemore',
        name: 'seemore',
        component: () => import('../views/seeMore.vue')
      },
      {
        path: '/recucontent',
        name: 'recucontent',
        component: () => import('../views/recruitcontent.vue')
      }

    ]
  },
  {
    path: '/admin',
    name: 'index',
    component: () => import('../layout/admin/index.vue'),
    redirect: '/admin/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: Home
      },
      {
        path: 'about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/About.vue')
      },
      {
        path: 'contact',
        name: 'Contact',
        component: () => import('../views/admin/Contact.vue')
      },
      {
        path: 'userMange',
        name: 'userMangement',
        component: () => import('../views/admin/userManagement.vue')
      },
      {
        path: 'datamange',
        name: 'datamangement',
        component: () => import('../views/admin/dataManagement.vue')
      },
      {
        path: 'Friendchain',
        name: 'Friendchain',
        component: () => import('../views/admin/Friendchain.vue')
      },
      {
        path: 'Userlist',
        name: 'Userlist',
        component: () => import('../views/admin/Userlist.vue')
      },
      {
        path: 'Rolelist',
        name: 'Rolelist',
        component: () => import('../views/admin/Rolelist.vue')
      }

    ]
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/admin/Login.vue')
  }
]

const createRouter = () => new VueRouter({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: routes
})

const router = createRouter()

// const router = new VueRouter({
//   routes
// })

router.beforeEach(function(to, from, next) {
  if (!localStorage.getItem('token')) {
    if (to.path !== '/login' && to.path.startsWith('/admin/')) {
      return next('/login')
    }
  }
  next()
})

export default router
