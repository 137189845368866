import request from '../../utils/request'
// 招聘详情
export function getUserLog(data = {}) {
  return request({
    url: `website/recordapply/getRecordApplyListByStaffId`,
    method: 'get',
    params: data
  })
}
// 招聘管理
export function getUsermanagement(data = {}) {
  console.log('getUsermanagement', data)
  return request({
    url: `/website/staff/list`,
    method: 'get',
    params: data
  })
}
// 获取type
export function getPare() {
  return request({
    url: `/website/subitem/getParentTye`,
    method: 'get'
  })
}
// 提交
export function getSubmit(data = {}) {
  return request({
    url: `/website/subitem/save`,
    method: 'get',
    data: data
  })
}
// 退出登录
// export function loginOut() {
//   return request({
//     url: `/admin/acl/index/logout`,
//     method: 'get'
//   })
// }
