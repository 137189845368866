import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router'

const service = axios.create({
  baseURL: '/api', // url=基本 api url+请求url
  withCredentials: true, // 跨域请求时发送cookie
  timeout: 10000// 请求超时
})

service.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['token'] = token
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code && res.code !== 20000) {
      // 登录超时,重新登录
      if (res.code === 20001) {
        router.push('/login')
        localStorage.removeItem('token')
        return
      }
      // if (res.code == 1015 || res.code == 1004 || res.code == 1021) {
      //     store.dispatch('resetToken').then(() => {
      //         location.reload()
      //     })
      // } else {
      Message({
        message: res.message || '未知异常',
        type: 'error',
        duration: 1500
      })
      // }
      return Promise.reject(res || 'error')
    } else {
      return Promise.resolve(res)
    }
  },
  error => {
    console.log('err' + error)// for debug
    return Promise.reject(error)
  }
)

export default service
